<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Conductores</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Orion</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">Conductor</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-info text-light">
                Competencias para conductores
                <span
                  v-if="form.nEstado"
                  class="badge"
                  :class="[
                    form.estado == 1
                      ? 'badge-secondary'
                      : form.estado == 2
                      ? 'badge-success'
                      : form.estado == 3
                      ? 'badge-danger'
                      : form.estado == 4
                      ? 'bg-olive'
                      : form.estado == 5
                      ? 'badge-warning'
                      : form.estado == 6
                      ? 'badge-info'
                      : form.estado == 7
                      ? 'bg-navy'
                      : form.estado == 8
                      ? 'bg-lime'
                      : '',
                  ]"
                  >{{ form.nEstado }}</span
                >
              </legend>
              <div class="row">
                <div class="col-md-9">
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label for="numero_documento">Documento</label>
                      <input
                        type="number"
                        :disabled="bloquear"
                        class="form-control"
                        id="numero_documento"
                        v-model="form.documento"
                        :class="
                          $v.form.documento.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @change="datosConductor(form.documento)"
                      />
                      <div style="color: red">
                        {{ isValidDocumento ? "" : "Ingresar solo números" }}
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="email">Correo</label>
                      <input
                        type="email"
                        class="form-control"
                        :disabled="bloquear"
                        id="email"
                        v-model="form.email"
                        :class="
                          $v.form.email.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @change="buscarCorreo()"
                      />
                      <div style="color: red">
                        {{ isValidEmail ? "" : "El correo es invalido" }}
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="nombres">Nombres</label>
                      <input
                        type="text"
                        class="form-control"
                        id="nombres"
                        :disabled="bloquear"
                        v-model="form.nombres"
                        :class="
                          $v.form.nombres.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                      <div style="color: red">
                        {{ isValidNombres ? "" : "Ingresar solo texto" }}
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="apellidos">Apellidos</label>
                      <input
                        type="text"
                        class="form-control"
                        id="apellidos"
                        :disabled="bloquear"
                        v-model="form.apellidos"
                        :class="
                          $v.form.apellidos.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                      <div style="color: red">
                        {{ isValidApellidos ? "" : "Ingresar solo texto" }}
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="tipo_documento">Tipo de Documento</label>
                      <v-select
                        class="form-control p-0"
                        v-model="form.tipo_documento"
                        :disabled="bloquear"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.tipo_documento.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                        ]"
                        placeholder="tipo_documento"
                        label="descripcion"
                        :options="listsForms.tipo_documento"
                        @input="selectTipoDocumento()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="fecha_nacimiento">Fecha de Nacimiento</label>
                      <input
                        type="date"
                        class="form-control"
                        id="fecha_nacimiento"
                        v-model="form.fecha_nacimiento"
                        :disabled="bloquear"
                        :class="
                          $v.form.fecha_nacimiento.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="tipo_contrato">Empresa</label>
                      <v-select
                        class="form-control p-0"
                        :disabled="bloquear"
                        v-model="form.empresa"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.empresa.$invalid ? 'is-invalid' : 'is-valid',
                        ]"
                        placeholder="empresa"
                        label="descripcion"
                        :options="listsForms.empresas"
                        @input="selectEmpresa()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="tipo_contrato">Activo al que entra</label>
                      <v-select
                        class="form-control p-0"
                        v-model="form.activo"
                        :disabled="bloquear"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.activo.$invalid ? 'is-invalid' : 'is-valid',
                        ]"
                        placeholder="activos"
                        label="descripcion"
                        :options="listsForms.activos"
                        @input="selectActivo()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="tipo_contrato">Tipo de contrato</label>
                      <v-select
                        class="form-control p-0"
                        v-model="form.tipo_contrato"
                        :disabled="bloquear"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.tipo_contrato.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                        ]"
                        placeholder="tipos_contratos"
                        label="descripcion"
                        :options="listsForms.tipos_contratos"
                        @input="selectTipoContrato()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="experiecia_cond"
                        >Años de experiencia en conducción</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        id="experiecia_cond"
                        :disabled="bloquear"
                        v-model="form.anios_experiencias"
                        :class="
                          $v.form.anios_experiencias.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                      <div style="color: red">
                        {{ isValidExperiencia ? "" : "Ingresar solo números" }}
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="fecha_insc_runt"
                        >Fecha de inscripción ante el RUNT</label
                      >
                      <input
                        type="date"
                        class="form-control"
                        :disabled="bloquear"
                        id="fecha_insc_runt"
                        v-model="form.fecha_insc_runt"
                        :class="
                          $v.form.fecha_insc_runt.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="categoria_licencia"
                        >Categoría de licencia</label
                      >
                      <v-select
                        class="form-control p-0"
                        v-model="form.categoria_licencia"
                        :disabled="bloquear"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.categoria_licencia.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                        ]"
                        placeholder="categoria_licencia"
                        label="descripcion"
                        :options="listsForms.categorias_licencias"
                        @input="selectCategoriaLicencia()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="restriccion_licencia"
                        >Restricción del conductor (Licencia de
                        conducción)</label
                      >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          :disabled="bloquear"
                          type="radio"
                          name="restriccion_licencia"
                          id="restriccion_licencia_si"
                          value="1"
                          v-model="form.restriccion_licencia"
                          :class="
                            $v.form.restriccion_licencia.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <label
                          class="form-check-label"
                          for="restriccion_licencia_si"
                        >
                          Si
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          checked
                          class="form-check-input"
                          :disabled="bloquear"
                          type="radio"
                          name="restriccion_licencia"
                          id="restriccion_licencia_no"
                          value="0"
                          v-model="form.restriccion_licencia"
                          :class="
                            $v.form.restriccion_licencia.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <label
                          class="form-check-label"
                          for="restriccion_licencia_no"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="clase_vehiculo">Clase De Vehículo</label>
                      <v-select
                        class="form-control p-0"
                        :disabled="bloquear"
                        v-model="form.clase_vehiculo"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.clase_vehiculo.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                        ]"
                        placeholder="clase_vehiculo"
                        label="descripcion"
                        :options="listsForms.clases_vehiculos"
                        @input="selectClaseVehiculo(), alert()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="comparendos">Tiene comparendos</label>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          :disabled="bloquear"
                          type="radio"
                          name="comparendos"
                          id="comparendos_si"
                          value="1"
                          v-model="form.comparendos"
                          :class="
                            $v.form.comparendos.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <label class="form-check-label" for="comparendos_si">
                          Si
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          checked
                          class="form-check-input"
                          :disabled="bloquear"
                          type="radio"
                          name="comparendos"
                          id="comparendos_no"
                          value="0"
                          v-model="form.comparendos"
                          :class="
                            $v.form.comparendos.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <label class="form-check-label" for="comparendos_no">
                          No
                        </label>
                      </div>
                    </div>
                    <div class="form-group col-md-12 text-center">
                      <label for="clase_vehiculo">Observaciones</label>
                      <textarea
                        class="form-control"
                        :disabled="bloquear"
                        id="fecha_insc_runt"
                        v-model="form.observaciones"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="imagen" class="text-center">Fotografía</label>
                  <div class="form-group" v-if="form.link_fotografia == null">
                    <input
                      v-show="!miniLogo"
                      style="color: red"
                      type="file"
                      class="form-control-file"
                      accept="image/x-png,image/gif,image/jpeg"
                      @change="obtenerImagen"
                      :disabled="bloquear"
                    />
                    <br v-show="!miniLogo" />
                    <p
                      class="text-center"
                      v-show="!miniLogo"
                      style="color: red"
                    >
                      Fotografía requerida (Max 2MB)
                    </p>
                  </div>
                  <figure class="form-group" v-if="miniLogo">
                    <div class="button-container">
                      <img
                        :src="miniLogo"
                        alt="Logo"
                        width="236"
                        height="125"
                        class="rounded mx-auto d-block"
                        :disabled="bloquear"
                      />
                      <a
                        @click="destroyImage()"
                        id="BotonEliminar"
                        :disabled="bloquear"
                        v-if="miniLogo"
                        href="#"
                        >Eliminar <i class="fas fa-window-close"></i
                      ></a>
                    </div>
                  </figure>
                </div>
              </div>
              <!-- <div
                class="justify-content-between text-center"
                v-show="form.id && !bloquear && solicita && miniLogo"
              >
                <button
                  type="button"
                  class="btn btn-warning col-md-1"
                  v-if="
                    $refs.SolicitudConductorDocumento
                      ? $refs.SolicitudConductorDocumento.estadoDocs
                      : false
                  "
                  @click="solicitar(form.id)"
                >
                  <i class="fas fa-clipboard-check"></i> Solicitar
                </button>
              </div> -->
              <div v-show="form.id">
                <SolicitudConductorDocumento
                  v-show="form.id != null"
                  ref="SolicitudConductorDocumento"
                >
                </SolicitudConductorDocumento>
              </div>
              <div class="justify-content-between text-center">
                <button
                  type="button"
                  class="btn btn-secondary col-md-1"
                  @click="back()"
                >
                  <i class="fas fa-reply"></i><br />Volver
                </button>
                <button
                  type="button"
                  class="btn bg-primary col-md-1"
                  @click="save(1)"
                  v-show="!$v.form.$invalid && !bloquear && miniLogo"
                >
                  <i class="fas fa-paper-plane"></i><br />
                  Guardar
                </button>
                <button
                  v-show="
                    form.id &&
                    !bloquear &&
                    solicita &&
                    miniLogo &&
                    form.estado == 1
                  "
                  type="button"
                  class="btn bg-warning col-md-1"
                  v-if="
                    $refs.SolicitudConductorDocumento
                      ? $refs.SolicitudConductorDocumento.estadoDocs
                      : false
                  "
                  @click="solicitar()"
                >
                  <i class="fas fa-clipboard-check"></i><br />
                  Solicitar
                </button>
              </div>
            </fieldset>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import moment from "moment";
import SolicitudConductorDocumento from "./SolicitudConductorDocumento";
import Loading from "../../../../components/Loading";
import {
  required,
  email,
  numeric,
  helpers,
  maxLength,
} from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[a-z A-Z \sÁÉÍÓÚáéíóúÑñ]*$/);

export default {
  name: "SolicitudConductor",
  components: { vSelect, SolicitudConductorDocumento, Loading },

  data() {
    return {
      swalInstance: null,
      observaciones_estado: "",
      cargando: false,
      estado_anterior: null,
      miniLogo: null,
      fileFotografia: null,
      fileFotografiaAnterior: null,
      solicita: false,
      accion: null,
      user_id: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      bloquear: null,
      modalDetalle: [],
      solicitudCond: {},
      formAnterior: {
        activo_id: null,
        anios_experiencias: null,
        apellidos: null,
        categoria_licencia_id: null,
        clase_vehiculo_id: null,
        comparendos: null,
        conductor_id: null,
        documento: null,
        email: null,
        empresa_id: null,
        fecha_insc_runt: null,
        fecha_nacimiento: null,
        nombres: null,
        restriccion_licencia: null,
        tipo_contrato_id: null,
        tipo_documento_id: null,
      },
      form: {
        id: null,
        conductor_id: null,
        documento: null,
        email: null,
        comparendos: null,
        apellidos: null,
        nombres: null,
        fecha_nacimiento: null,
        restriccion_licencia: null,
        fecha_insc_runt: null,
        anios_experiencias: null,
        clase_vehiculo: {},
        clase_vehiculo_id: null,
        categoria_licencia: {},
        categoria_licencia_id: null,
        tipo_contrato: {},
        tipo_contrato_id: null,
        activo: {},
        activo_id: null,
        empresa: {},
        empresa_id: null,
        tipo_documento: {},
        tipo_documento_id: null,
        observaciones: "",
        link_fotografia: null,
        estado: null,
        nEstado: null,
      },
      listsForms: {
        empresas: [],
        activos: [],
        tipos_contratos: [],
        categorias_licencias: [],
        clases_vehiculos: [],
        solicitudConductores: [],
        tipo_documento: [],
      },
    };
  },

  validations: {
    form: {
      documento: {
        required,
        numeric,
      },
      email: {
        required,
        email,
      },
      nombres: {
        required,
        alpha,
      },
      apellidos: {
        required,
        alpha,
      },
      tipo_documento: {
        required,
      },
      fecha_nacimiento: {
        required,
      },
      empresa: {
        required,
      },
      activo: {
        required,
      },
      tipo_contrato: {
        required,
      },
      anios_experiencias: {
        required,
        numeric,
        maxLength: maxLength(2),
      },
      fecha_insc_runt: {
        required,
      },
      categoria_licencia: {
        required,
      },
      restriccion_licencia: {
        required,
      },
      clase_vehiculo: {
        required,
      },
      comparendos: {
        required,
      },
    },
  },

  methods: {
    async init() {
      let me = this;
      me.accion = me.$route.params.accion;
      if (me.$route.params.accion == "Editar") {
        let formEdit = me.$route.params.data_edit;
        me.form.id = formEdit.id;
        me.form.estado = formEdit.estado;
        me.estado_anterior = formEdit.estado;
        me.form.nombres = formEdit.nombres;
        me.form.apellidos = formEdit.apellidos;
        me.form.observaciones = formEdit.observaciones;
        me.form.documento = formEdit.numero_documento;
        me.form.fecha_nacimiento = formEdit.fecha_nacimiento;
        me.form.anios_experiencias = formEdit.experiencia_cond;
        me.form.fecha_insc_runt = formEdit.fecha_insc_runt;
        me.form.comparendos = formEdit.comparendos;
        me.form.restriccion_licencia = formEdit.restriccion_licencia;
        me.form.email = formEdit.email;
        me.form.nEstado = formEdit.Estado;
        me.user_id = formEdit.user_id;
        if (formEdit.link_foto) {
          me.miniLogo = me.uri_docs + formEdit.link_foto;
        }
        me.fileFotografia = formEdit.link_foto ? formEdit.link_foto : null;
        await me.getUltimaSolicitud(formEdit.numero_documento);

        if (me.solicitudCond <= 1) {
          me.solicitudCond = {
            email: false,
          };
        } else {
          me.solicitudCond = {
            email: true,
          };
        }
        if (formEdit.tipo_documento) {
          me.form.tipo_documento_id = formEdit.tipo_documento;
          let tipoDoc = me.listsForms.tipo_documento.find(
            (t) => t.numeracion === formEdit.tipo_documento
          );
          me.form.tipo_documento = tipoDoc;
        }
        if (formEdit.activo) {
          me.form.activo_id = formEdit.activo;
          let activo = me.listsForms.activos.find(
            (a) => a.numeracion === formEdit.activo
          );
          me.form.activo = activo;
        }
        if (formEdit.empresa) {
          me.form.empresa_id = formEdit.empresa;
          let empresa = me.listsForms.empresas.find(
            (e) => e.numeracion === formEdit.empresa
          );
          me.form.empresa = empresa;
        }
        if (formEdit.categoria_licencia) {
          me.form.categoria_licencia_id = formEdit.categoria_licencia;
          let categoria = me.listsForms.categorias_licencias.find(
            (c) => c.numeracion === formEdit.categoria_licencia
          );
          me.form.categoria_licencia = categoria;
        }
        if (formEdit.tipo_contrato) {
          me.form.tipo_contrato_id = formEdit.tipo_contrato;
          let tipoCon = me.listsForms.tipos_contratos.find(
            (c) => c.numeracion === formEdit.tipo_contrato
          );
          me.form.tipo_contrato = tipoCon;
        }
        if (formEdit.clase_vehiculo) {
          me.form.clase_vehiculo_id = formEdit.clase_vehiculo;
          let claseVh = me.listsForms.clases_vehiculos.find(
            (c) => c.numeracion === formEdit.clase_vehiculo
          );
          me.form.clase_vehiculo = claseVh;
        }
        me.formAnterior = {
          activo_id: me.form.activo_id,
          anios_experiencias: me.form.anios_experiencias,
          apellidos: me.form.apellidos,
          categoria_licencia_id: me.form.categoria_licencia_id,
          clase_vehiculo_id: me.form.clase_vehiculo_id,
          comparendos: me.form.comparendos,
          conductor_id: me.form.conductor_id,
          documento: me.form.documento,
          email: me.form.email,
          empresa_id: me.form.empresa_id,
          fecha_insc_runt: me.form.fecha_insc_runt,
          fecha_nacimiento: me.form.fecha_nacimiento,
          nombres: me.form.nombres,
          restriccion_licencia: me.form.restriccion_licencia,
          tipo_contrato_id: me.form.tipo_contrato_id,
          tipo_documento_id: me.form.tipo_documento_id,
        };
        me.fileFotografiaAnterior = me.fileFotografia;
        me.$refs.SolicitudConductorDocumento.getSolicitudConductor(me.form.id);
      } else {
        me.accion = "Crear";
        me.user_id = me.$store.state.user.id;
      }

      if (me.$store.state.user.empresas.length > 0) {
        let emp_id = me.$store.state.user.empresas[0].pivot
          ? me.$store.state.user.empresas[0].pivot.empresa_id
          : null;
        let empresa =
          emp_id != null
            ? me.listsForms.empresas.find((e) => e.cod_alterno == emp_id)
            : null;
        if (empresa) {
          me.form.empresa = empresa;
          me.form.empresa_id = empresa.numeracion;
        }
      }

      if (me.form.estado && me.form.estado == 8) {
        me.bloquear = true;
      }
    },

    setBloquear() {
      if (this.form.estado) {
        if (this.form.estado > 1) {
          this.bloquear = true;
        }
      }
    },

    async buscarCorreo() {
      if (this.form.email) {
        await axios
          .get(
            "api/funcionariosFrontera/HseSolicitudesConductores/validarEmail",
            {
              params: {
                documento: this.form.documento,
                email: this.form.email,
              },
            }
          )
          .then((response) => {
            if (response.data > 0) {
              this.$swal({
                title: "Email Invalido",
                text: "Ya se encuentra este correo asociado a otro conductor",
                icon: "warning",
                confirmButtonText: "Ok!",
              }).then(() => {
                this.form.email = null;
              });
            }
          });
      }
    },

    async getConductor(documento) {
      await axios
        .get(
          "api/funcionariosFrontera/HseSolicitudesConductores/conductor/" +
            documento
        )
        .then((response) => {
          this.conductor = response.data;
        });
    },

    async getUltimaSolicitud(documento) {
      if (this.accion == "Editar") {
        await axios
          .get(
            "api/funcionariosFrontera/HseSolicitudesConductores/ultimaSolicitud",
            {
              params: {
                accion: this.accion,
                documento: documento,
              },
            }
          )
          .then((response) => {
            this.solicitudCond = response.data;
          });
      } else {
        await axios
          .get(
            "api/funcionariosFrontera/HseSolicitudesConductores/ultimaSolicitud",
            {
              params: {
                accion: this.accion,
                documento: documento,
              },
            }
          )
          .then((response) => {
            this.solicitudCond = response.data;
          });
      }
    },

    async getEmpresas() {
      await axios.get("/api/lista/164").then((response) => {
        this.listsForms.empresas = response.data;
      });
    },

    async getActivos() {
      await axios.get("/api/lista/117").then((response) => {
        this.listsForms.activos = response.data;
      });
    },

    async getTiposContratos() {
      await axios.get("/api/lista/26").then((response) => {
        this.listsForms.tipos_contratos = response.data;
      });
    },

    async getCategoriasLicencias() {
      await axios.get("/api/lista/17").then((response) => {
        this.listsForms.categorias_licencias = response.data;
      });
    },

    async getClasesVehiculos() {
      await axios.get("/api/lista/163").then((response) => {
        this.listsForms.clases_vehiculos = response.data;
      });
    },

    async getTipoDocumentos() {
      await axios.get("/api/lista/6").then((response) => {
        this.listsForms.tipo_documento = response.data;
      });
    },

    async save(accion) {
      this.cerrarAlerta();
      let me = this;
      me.cargando = true;
      let formData = new FormData();
      formData.append("accion", me.accion);
      formData.append("id", me.form.id);
      formData.append(
        "conductor_id",
        me.form.conductor_id ? me.form.conductor_id : ""
      );
      formData.append("empresa", me.form.empresa_id);
      formData.append("activo", me.form.activo_id);
      formData.append("nombres", me.form.nombres);
      formData.append("apellidos", me.form.apellidos);
      formData.append("numero_documento", me.form.documento);
      formData.append("tipo_contrato", me.form.tipo_contrato_id);
      formData.append("experiencia_cond", me.form.anios_experiencias);
      formData.append("clase_vehiculo", me.form.clase_vehiculo_id);
      formData.append("comparendos", me.form.comparendos);
      formData.append("restriccion_licencia", me.form.restriccion_licencia);
      formData.append("fecha_insc_runt", me.form.fecha_insc_runt);
      formData.append("tipo_documento", me.form.tipo_documento_id);
      formData.append("fecha_nacimiento", me.form.fecha_nacimiento);
      formData.append("email", me.form.email);
      formData.append("categoria_licencia", me.form.categoria_licencia_id);
      formData.append("observaciones", me.form.observaciones);
      formData.append("link_fotografia", me.fileFotografia);
      formData.append("user_id", me.user_id);
      formData.append(
        "observaciones_estado",
        me.observaciones_estado
          ? me.observaciones_estado
          : "Actualización automática del sistema por creación o ajustes de la solicitud inicial."
      );
      if (me.form.id) {
        await me.compararFormularios(me.formAnterior, me.form);
        if (accion == 2) {
          me.form.estado = 7;
        }
        if (me.estado_anterior == 5 || me.estado_anterior == 3) {
          formData.append("estado", 1);
        } else {
          formData.append("estado", me.form.estado);
        }
        formData.append("estado_anterior", me.estado_anterior);
        await axios
          .post("api/funcionariosFrontera/HseSolicitudesConductores", formData)
          .then(async (response) => {
            me.form.id = response.data.id;
            me.form.estado = response.data.estado;
            me.form.nEstado = response.data.nEstado;
            me.estado_anterior = response.data.estado;
            me.formAnterior = {
              activo_id: response.data.activo,
              anios_experiencias: response.data.experiencia_cond,
              apellidos: response.data.apellidos,
              categoria_licencia_id: response.data.categoria_licencia,
              clase_vehiculo_id: response.data.clase_vehiculo,
              comparendos: response.data.comparendos,
              conductor_id: response.data.conductor_id,
              documento: response.data.numero_documento,
              email: response.data.email,
              empresa_id: response.data.empresa,
              fecha_insc_runt: response.data.fecha_insc_runt,
              fecha_nacimiento: response.data.fecha_nacimiento,
              nombres: response.data.nombres,
              restriccion_licencia: response.data.restriccion_licencia,
              tipo_contrato_id: response.data.tipo_contrato,
              tipo_documento_id: response.data.tipo_documento,
            };
            me.fileFotografiaAnterior = response.data.link_foto;
            await me.$refs.SolicitudConductorDocumento.getSolicitudConductor(
              response.data.id
            );
            let error = response.data.msg;
            let msg = "";
            let icon = "";
            if (error) {
              msg = error;
              icon = "error";
            } else {
              msg = "Los datos se guardaron exitosamente...";
              icon = "success";
            }
            me.$swal({
              icon: icon,
              title: msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            error ? this.back() : "";
            me.cargando = false;
            me.setBloquear();
          })
          .catch(function (error) {
            me.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        me.cargando = false;
      } else {
        formData.append("estado", 1);
        await axios
          .post("api/funcionariosFrontera/HseSolicitudesConductores", formData)
          .then(async (response) => {
            me.form.id = await response.data.id;
            me.form.estado = await response.data.estado;
            me.form.nEstado = await response.data.nEstado;
            me.estado_anterior = await response.data.estado;
            me.accion = "Editar";
            me.formAnterior = {
              activo_id: await response.data.activo,
              anios_experiencias: await response.data.experiencia_cond,
              apellidos: await response.data.apellidos,
              categoria_licencia_id: await response.data.categoria_licencia,
              clase_vehiculo_id: await response.data.clase_vehiculo,
              comparendos: await response.data.comparendos,
              conductor_id: await response.data.conductor_id,
              documento: await response.data.numero_documento,
              email: await response.data.email,
              empresa_id: await response.data.empresa,
              fecha_insc_runt: await response.data.fecha_insc_runt,
              fecha_nacimiento: await response.data.fecha_nacimiento,
              nombres: await response.data.nombres,
              restriccion_licencia: await response.data.restriccion_licencia,
              tipo_contrato_id: await response.data.tipo_contrato,
              tipo_documento_id: await response.data.tipo_documento,
            };
            me.fileFotografiaAnterior = await response.data.link_foto;
            me.$refs.SolicitudConductorDocumento.getSolicitudConductor(
              response.data.id
            );
            me.$swal({
              icon: "success",
              title: "Los datos se guardaron exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.setBloquear();
          })
          .catch(function (error) {
            me.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
      me.cargando = false;
    },

    changeEstado(observaciones_estado) {
      let dataSol = {
        solicitud_id: this.form.id,
        estado_nuevo: 1,
        estado_anterior: this.form.estado,
        observaciones_estado,
      };
      if (this.form.estado > 1) {
        axios
          .post(
            "/api/funcionariosFrontera/HseSolicitudesConductores/changeEstado",
            dataSol
          )
          .then((response) => {
            let data = response.data;
            this.form.estado = data.estado;
            this.form.nEstado = data.nEstado;
          });
      }
    },

    selectEmpresa() {
      this.form.empresa_id = null;
      if (this.form.empresa) {
        this.form.empresa_id = this.form.empresa.numeracion;
      }
    },

    selectActivo() {
      this.form.activo_id = null;
      if (this.form.activo) {
        this.form.activo_id = this.form.activo.numeracion;
      }
    },

    selectTipoContrato() {
      this.form.tipo_contrato_id = null;
      if (this.form.tipo_contrato) {
        this.form.tipo_contrato_id = this.form.tipo_contrato.numeracion;
      }
    },

    selectCategoriaLicencia() {
      this.form.categoria_licencia_id = null;
      if (this.form.categoria_licencia) {
        this.form.categoria_licencia_id =
          this.form.categoria_licencia.numeracion;
      }
    },

    selectClaseVehiculo() {
      this.form.clase_vehiculo_id = null;
      if (this.form.clase_vehiculo) {
        this.form.clase_vehiculo_id = this.form.clase_vehiculo.numeracion;
      }
    },

    selectTipoDocumento() {
      this.form.tipo_documento_id = null;
      if (this.form.tipo_documento) {
        this.form.tipo_documento_id = this.form.tipo_documento.numeracion;
      }
    },

    async datosConductor(documento) {
      this.cargando = true;
      await this.getConductor(documento);
      await this.getUltimaSolicitud(documento);
      if (this.solicitudCond.numero_documento) {
        this.$swal({
          title: "Atención!",
          text: `
            El conductor ${this.solicitudCond.nombres} ${
            this.solicitudCond.apellidos
          } 
            identificado con ${this.solicitudCond.nTipoDocumento} No. ${
            this.solicitudCond.numero_documento
          } 
            ya se encuentra registrado con la solicitud ${
              this.solicitudCond.id
            } 
            ${
              this.solicitudCond.user
                ? " por el usuario " + this.solicitudCond.user.name
                : ""
            }
            de la empresa ${this.solicitudCond.nEmpresa}.`,
          icon: "warning",
          confirmButtonText: "Entendido!",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            this.back();
          }
        });
      } else if (this.conductor.numero_documento) {
        this.miniLogo = null;
        this.fileFotografia = null;
        this.form.nombres = this.conductor.nombres;
        this.form.apellidos = this.conductor.apellidos;
        this.form.email = this.conductor.email;
        this.form.fecha_nacimiento = this.conductor.fecha_nacimiento;
        this.form.tipo_documento = this.conductor.tipo_documento;
        this.form.conductor_id = this.conductor.id ? this.conductor.id : null;

        if (this.conductor.link_fotografia) {
          this.miniLogo = this.uri_docs + this.conductor.link_fotografia;
          this.fileFotografia = this.conductor.link_fotografia;
        }

        if (this.conductor.tipo_documento) {
          const tipoDocumento = this.listsForms.tipo_documento.find(
            (tipoDoc) => tipoDoc.numeracion === this.conductor.tipo_documento
          );
          this.form.tipo_documento = tipoDocumento;
          this.selectTipoDocumento();
        }

        if (this.conductor.categoria_licencia) {
          const categoriaLicencia = this.listsForms.categorias_licencias.find(
            (catLicencia) =>
              catLicencia.numeracion === this.conductor.categoria_licencia
          );
          this.form.categoria_licencia = categoriaLicencia;
          this.selectCategoriaLicencia();
        }
      } else {
        this.miniLogo = null;
        this.fileFotografia = null;
        this.form = {
          id: null,
          documento: await documento,
          email: null,
          comparendos: null,
          apellidos: null,
          nombres: null,
          fecha_nacimiento: null,
          restriccion_licencia: null,
          fecha_insc_runt: null,
          anios_experiencias: null,
          clase_vehiculo: {},
          clase_vehiculo_id: null,
          categoria_licencia: {},
          categoria_licencia_id: null,
          tipo_contrato: {},
          tipo_contrato_id: null,
          activo: {},
          activo_id: null,
          empresa: {},
          empresa_id: null,
          tipo_documento: {},
          tipo_documento_id: null,
          observaciones: "",
        };
      }
      this.cargando = false;
    },

    SoloLetras(letra) {
      let tecla = document.all ? letra.keyCode : letra.which;

      //Tecla de retroceso para borrar, y espacio siempre la permite
      if (tecla == 8 || tecla == 32) {
        return true;
      }

      // Patrón de entrada
      let patron = /[A-Za-z]/;
      let tecla_final = String.fromCharCode(tecla);
      return patron.test(tecla_final);
    },

    back() {
      this.cerrarAlerta();
      return this.$router.replace("/FuncionariosFrontera/SolicitudesConductor");
    },

    async solicitar() {
      this.observaciones_estado = "";
      let expired_files =
        this.$refs.SolicitudConductorDocumento.validarFechasSolicitar();

      if (expired_files.length > 0) {
        this.form.estado = 3;
        let description_files = expired_files
          .map((file, index, arr) => {
            if (index === 0) {
              return "( " + file;
            } else if (index === arr.length - 1) {
              return " y " + file;
            } else {
              return ", " + file;
            }
          })
          .join("");

        this.observaciones_estado =
          "Rechazado por Documentos vencidos " + description_files + " )";

        await this.save(2);
      } else {
        await this.save(2);
      }
    },

    async validarHorario() {
      let me = this;
      let horaAct = moment().format("HH");
      if (horaAct >= 4 && horaAct < 19) {
        await me.init();
      } else {
        me.$swal({
          title: "Horario de atención:",
          text: "4:00AM - 7:00 PM",
          icon: "warning",
          confirmButtonText: "Ok!",
        }).then(() => {
          me.back();
        });
      }
    },

    // Funciones para carga de imagenes
    obtenerImagen(e) {
      this.miniLogo = null;

      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;

          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    destroyImage() {
      this.miniLogo = null;
      this.fileFotografia = null;
    },

    alert() {
      this.solicita = false;
      this.swalInstance = this.$swal({
        icon: "warning",
        title: `Si desea cambiar el paquete de documentos debe GUARDAR...`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });
    },

    cerrarAlerta() {
      if (this.swalInstance) {
        this.swalInstance.close();
      }
    },

    async compararFormularios(form1, form2) {
      for (let key in form1) {
        if (form1[key] !== form2[key]) {
          this.form.estado = 1;
        }
      }
      if (this.fileFotografiaAnterior !== this.fileFotografia) {
        this.form.estado = 1;
      }
    },
  },

  computed: {
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
    },

    isValidDocumento() {
      return /[0-9]/.test(this.form.documento);
    },

    isValidExperiencia() {
      return /[0-9]/.test(this.form.anios_experiencias);
    },

    isValidNombres() {
      return /^[/a-z/A-Z\sÁÉÍÓÚáéíóúÑñ]*$/.test(this.form.nombres);
    },

    isValidApellidos() {
      return /^[/a-z/A-Z\sÁÉÍÓÚáéíóúÑñ]*$/.test(this.form.apellidos);
    },
  },

  async mounted() {
    let me = this;
    me.cargando = true;
    //await me.validarHorario();
    await me.getEmpresas();
    await me.getActivos();
    await me.getTiposContratos();
    await me.getCategoriasLicencias();
    await me.getClasesVehiculos();
    await me.getTipoDocumentos();
    await me.init();
    me.cargando = false;
  },
};
</script>

<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgb(248, 8, 8);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgb(248, 8, 8), 0 0 20px rgb(248, 8, 8);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  height: 100%;
  min-height: 20px;
  padding: 14px;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  border: 2px solid #09cce6;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
